import * as React from "react";

type AspectRatioBoxProps = React.HTMLAttributes<any> & {
  fill?: string;
  ratio: number;
};

export const AspectRatioBox: React.FunctionComponent<
  AspectRatioBoxProps
> = props => {
  const { ratio, fill, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        height: "0",
        overflow: "hidden",
        paddingTop: `${ratio * 100}%`,
        background: fill || `rgba(0, 0, 0, 0.5)`,
        position: "relative"
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
