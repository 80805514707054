import * as React from "react";
import { Article } from "src/Data/Project/Article";
import styled from "styled-components";
import { Project } from "src/Data/Project";
import { DateUtil } from "src/DateUtil";
import { ViewMediaItem, ViewCarousel, ViewGallery, ViewImage } from "./Media";
import { Context, ColorSource } from "src/Context";
import { Link } from "@reach/router";
import { ViewSpinner } from "./Spinner";
import { Image, MediaItem } from "src/Data/Media";
import { AspectRatioBox } from "./AspectRatioBox";
import {
  globalMargins,
  MOBILE_MARGIN,
  DESKTOP_MARGIN
} from "src/GlobalMargins";
import { LazyRender } from "src/LazyRender";

interface ProjectContainerProps {
  textColor: string;
  backgroundColor: string;
  coverUrl?: string;
}

const PROJECT_PADDING_Y = "2rem";
const GRID_ROW_GAP = "2rem";

// todo
// replace videos with thumpnail
// abstract project preview from small preview
// allign to the right to make space  for the menu
// read more button should be lower
// 

export const BaseProjectContainer = styled.div<ProjectContainerProps>`
  padding-top: ${PROJECT_PADDING_Y};
  padding-bottom: ${PROJECT_PADDING_Y};

  ${globalMargins}
  display: grid;
  
  @media(min-width: 1280px){
    grid-template-areas: "title title kicker kicker"
                         "cover cover cover cover"
                         "intro intro intro ."
                         "readMore readMore . ."
                         "article article article article";
  }

  @media(max-width: 1279px){
    grid-template-areas: "title title title title"
                         "cover cover cover cover"
                         "kicker kicker kicker kicker"
                         "intro intro intro ."
                         "readMore readMore . ."
                         "article article article article";  
}


 
  @media (min-width: 1280px) {
    .indent,
    .text {
      padding-left: 2rem;
    }
  }


  // > div {
  //   border:solid 2px white;
  // }



  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;

  
  grid-row-gap: ${GRID_ROW_GAP};


  color: ${props => props.textColor}
  background: ${props => props.backgroundColor}
  //background-color: red;

  // div {
  //   border: 1px solid black;
  // }

  // a {
  //   border: 1px solid black;
  // }

  .title {
     font-size: 2rem;
     grid-area: title;
  }

  .kicker {
     grid-area: kicker;
     font-size: 1rem;

     @media(min-width: 1280px){
       font-size: 1.5rem;
     }
  }

  .cover {
    grid-area: cover;
    margin-left: -${MOBILE_MARGIN};
    margin-right: -${MOBILE_MARGIN};

    @media(min-width: 1280px){
      margin-left: 0;
      margin-right: 0;
    }
  }

  .intro {
    font-size: 1.5rem;
    grid-area: intro;
    
    @media (max-width: 1279px) {
      display: none;
    }
  }

  .readMore {

    grid-area: readMore;
  }
`;

export const ProjectContainerWithBackgroundImage = styled(BaseProjectContainer)`
  
  
  //position: relative;
  & > * {
    z-index: 1;
  }
  .imageWrapper {
    //border: 1px solid white;
    //opacity: 0.4;
    //position: absolute;
    position: relative;
    z-index: 0;
    //width: 100% !important;
    height: 450px;
    width:400%;
    top: 0;
    //left: 10%;
    left: 0;
    position: relative;
    
     overflow: hidden;

    img {

      // // width: unset !important;
      // // height: 100%;
      // // max-height: unset !important;
      // // //transform: translate(0, -50%);
      // // object-fit: cover;
      // height: 100%;
      // width: 100%;
      // max-height: 100vh;
      // max-width: 100vh;
      // object-fit: contain;

       // position: absolute;
       // //max-width: 100%;
       // //width: 100%;
       // height: 450px;
       // //width: auto;

       // top: 50%;     
       // left: 50%;
       // transform: translate( -50%, -50%);

         width:100%;
        height:450px;
        object-fit:cover;
    }

    // img.landscape {
    //   height: 100%;
    //   width: auto;
    // }

  }
`;

export const FullProjectContainer = styled(BaseProjectContainer)`
  .intro {
    display: initial !important;
  }
  .article {
    grid-area: article;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 2rem;
    width: 100%;
    & > .text:first-child {
      margin-top: -4rem;
    }
  }

  .text {
    grid-column: 1 / span 4;
    font-size: 1rem;

    @media (min-width: 1280px) {
      grid-column: 1 / span 3;
      font-size: unset;
    }

    p {
      padding-bottom: 1rem;
    }

    h1,
    h2,
    h3,
    h4 {
      text-indent: 2rem;
      padding-bottom: 2rem;
      padding-top: 1rem;
      text-transform: uppercase;
      font-weight: normal;
    }

    table,
    tr,
    td {
      border-style: none;
    }
  }

  .mediaItem {
    grid-column: 1 / span 4;
    margin-left: -${MOBILE_MARGIN};
    margin-right: -${MOBILE_MARGIN};
    .caption {
      padding-left: ${MOBILE_MARGIN};
      @media (min-width: 1280px) {
        padding-left: 2rem;
        padding-right: 2rem;
        max-width: 50vw;
      }
    }
    @media (min-width: 1280px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .gallery {
    grid-column: 1 / span 4;
  }

  .share {
    padding-top: 1rem;
    @media (min-width: 1280px) {
      padding-top: 5rem;
    }
    grid-column: 1;
    .icons {
      padding-top: 5px;
      display: flex;
    }

    .SocialMediaShareButton:not(:last-child) {
      padding-right: 2px;
    }

    .SocialMediaShareButton {
      cursor: pointer;
    }
  }

  .table {
    grid-column: 1 / span 3;
    @media (min-width: 1280px) {
      padding-top: 2rem;
    }
    .tableEntry {
      display: grid;
      padding-bottom: 1rem;
      font-size: 1rem;
      @media (min-width: 1280px) {
        font-size: unset;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 0;
      }
      .comma:after {
        content: " ";
      }
    }
  }
`;

const ViewArticleText: React.FunctionComponent<{
  text: Article.Text;
}> = props => {
  return (
    <div
      className="text"
      dangerouslySetInnerHTML={{ __html: props.text.content }}
    />
  );
};

interface TableEntry {
  key: string;
  values: {
    label: string;
    url?: string;
  }[];
}

export const ViewFactsTable: React.FunctionComponent<{
  entries: TableEntry[];
}> = props => {
  return (
    <div className="table indent monospace">
      {props.entries.map(it => {
        return (
          <div key={it.key} className="tableEntry">
            <div className="bold">{it.key}</div>
            <div>
              {it.values.map((v, i) => {
                const el = v.url ? (
                  <a key={v.label} href={v.url} target="__blank">
                    {v.label}
                  </a>
                ) : (
                  <span key={v.label}>{v.label}</span>
                );
                return i < it.values.length - 1 ? (
                  <React.Fragment key={v.label}>
                    {el}
                    <span key="comma" className="comma">
                      ,
                    </span>
                  </React.Fragment>
                ) : (
                  el
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const ViewProjectArticle: React.FunctionComponent<{
  article: Article;
}> = props => {
  const viewItems = props.article
    .filter(
      () => true
      /* item =>
       *   item.__typename === "GalleryRecord" && item.displayAs === "CAROUSEL" */
      /* item =>
       *   item.__typename === "ArticleMediaItemRecord" &&
       *      item.item.__typename === "IframeRecord" */
      /* item.item.__typename === "VideoRecord" */
    )
    .map(item => {
      switch (item.__typename) {
        case "TextRecord":
          return <ViewArticleText key={item.id} text={item} />;
        case "ArticleMediaItemRecord":
          return (
            <ViewMediaItem
              key={item.id}
              className="mediaItem"
              mediaItem={item.item}
              lightBoxEnabled
              imageRenderOpts={{
                lazy: true,
                sizes: "100vw",
                renderMode: item.item.__typename === "ImageRecord" &&
                  (item.item.proportions === "16:9" ||
                    !item.item.proportions) && {
                    type: "aspectRatio",
                    ratio: [16, 9]
                  }
              }}
            />
          );

        case "GalleryRecord": {
          const stackGridColumnWidth =
            typeof window !== "undefined" &&
            window.matchMedia("(min-width: 1280px").matches &&
            item.items.length >= 3
              ? "33%"
              : "50%";

          return (
            <ViewGallery
              key={item.id}
              className="mediaItem"
              stackGridColumnWidth={stackGridColumnWidth}
              gallery={item}
            />
          );
        }
      }
    });
  return <React.Fragment>{viewItems}</React.Fragment>;
};

export const collectTableEntries = (project: Project.Full): TableEntry[] => {
  const facts = project.otherFacts.map(d => {
    return {
      key: d.label,
      values: [{ label: d.value, url: d.url }]
    };
  });
  const partners = project.partners.map(d => {
    return {
      key: d.role,
      values: d.names.map(({ name, url }) => ({ label: name, url }))
    };
  });

  const location: TableEntry | undefined = project.location && {
    key: "Location",
    values: [
      {
        label: project.location.label,
        url:
          project.location.geoLocation &&
          `https://map.google.com?latitude=${
            project.location.geoLocation.latitude
          }&longitude=${project.location.geoLocation.longitude}`
      }
    ]
  };

  const date: TableEntry = {
    key: project.isOngoing ? "Ongoing since" : "Date",
    values: [{ label: DateUtil.format(project.date, DateUtil.YEAR) }]
  };

  return [date, location, ...partners, ...facts].reduce(
    (acc, a) => {
      if (a) {
        return acc.concat(a);
      } else {
        return acc;
      }
    },
    [] as TableEntry[]
  );
};

export const containProject = ({
  project,
  nodeId,
  ctx,
  Wrap
}: {
  project: Project.Featured | Project.Full | Project.Preview;
  Wrap: React.ComponentType<ProjectContainerProps>;
  ctx: Context;
  nodeId: string;
}) => (children: JSX.Element) => {
  return (
    <ColorSource
      nodeId={nodeId}
      ctx={ctx}
      foreground={project.textColor.hex}
      background={project.backgroundColor.hex}
      className="project"
    >
      <Wrap
        textColor={project.textColor.hex}
        backgroundColor={project.backgroundColor.hex}
      >
        {children}
      </Wrap>
    </ColorSource>
  );
};

export const ViewCover = (props: { cover: MediaItem }) => {
  return (
    <ViewMediaItem
      hideCaption
      className="cover"
      mediaItem={props.cover}
      videoProps={
        {
          disableControls: true
        } as any
      }
      imageRenderOpts={{
        renderMode: { type: "aspectRatio", ratio: [16, 9] },
        sizes: "(min-width: 1280px) 80vw, 100vw",
        background: true,
        lazy: true
      }}
    />
  );
};

export const ViewFeaturedProject: React.FunctionComponent<{
  project: Project.Featured;
  ctx: Context;
}> = props => {
  const { project, ctx } = props;
  return containProject({
    project,
    ctx,
    nodeId: project.slug,
    Wrap: BaseProjectContainer
  })(
    <React.Fragment>
      <div className="indent title">{project.title}</div>
      <div className="kicker">{project.kicker}</div>
      <ViewCover cover={project.cover} />
      <div
        className="intro text"
        dangerouslySetInnerHTML={{ __html: project.introText }}
      />
      <Link
        className="pointer readMore indent"
        to={`/projects/${project.slug}`}
      >
        Read more
      </Link>
    </React.Fragment>
  );
};

export const ViewArchivedProject: React.FunctionComponent<{
  project: Project.Preview;
  ctx: Context;
}> = ({ project, ctx }) => {
  const coverImage =
    (project.cover.__typename === "ImageRecord" && project.cover) ||
    project.coverImage ||
    null;

  
  const showCover =
    typeof window !== "undefined" &&
    window.matchMedia("(max-width: 1279px)").matches;


// {showCover && }
// <ViewCover cover={project.cover} />


  return containProject({
    project,
    ctx,
    nodeId: project.slug,
    Wrap: showCover ? BaseProjectContainer : ProjectContainerWithBackgroundImage
  })(
    <React.Fragment>
      
      <div className="indent title">{project.title}</div>
      {project.kicker && <div className="kicker">{project.kicker}</div>}
      
      {!showCover && coverImage && (
          <ViewImage file={coverImage.file} to={`/projects/${project.slug}`} />
      )}      

      <Link className="link readMore indent" to={`/projects/${project.slug}`}>
        Read more
      </Link>
    </React.Fragment>
  );
};

const ProjectArchive = styled.div`
  .cover {
    // @media (min-width: 1280px) {
    //   display: none;
    // }
  }

  background-color:rgb(220, 220, 220);

  // border-left:50px solid transparent;
  // border-right:50px solid transparent;

  display: grid;

  .project div {
    grid-template-areas: "title title title title"
                       "cover cover cover cover"
                       "kicker kicker kicker kicker"
                       "readMore readMore . ."
                       "article article article article" !important;
  }
  
  @media(min-width: 768px){
    grid-template-columns: 100%;
  }

  @media(min-width: 1280px){
    grid-template-columns: 50% 50%;

    .project:nth-child(odd) {
        padding-left: 100px;
      }
    
      .project:nth-child(even) {
        padding-right: 50px;
      }
  }

  @media(min-width: 1900px){
    grid-template-columns: 33.33% 33.33% 33.33%;

    .project:nth-child(odd) {
      padding-left: 0px;
    }
  
    .project:nth-child(even) {
      padding-right: 0px;
    }

    .project:nth-child(3n-2) {
      padding-left: 80px;
    }
  
    .project:nth-child(3n) {
      padding-right: 80px;
    }
  }
  

`;

const ProjectArchiveLoadMore = styled.div`
  
  background-color:gray;
  display: grid;
  
  .load-more-container {
    
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    padding-left:5%;
  }

  .load-more {
    cursor: pointer;
  }`;

interface ProjectArchiveState {
  archivedProjects: Project.Preview.PagedProjects;
  request: null | "error" | "loading";
}

interface ProjectArchiveProps {
  ctx: Context;
  archivedProjects: Project.Preview.PagedProjects;
  filter?: Project.Preview.Filter;
}

export class ViewProjectArchive extends React.Component<
  ProjectArchiveProps,
  ProjectArchiveState
> {
  constructor(props: ProjectArchiveProps) {
    super(props);
    this.state = {
      archivedProjects: props.archivedProjects,
      request: null
    };
  }

  componentDidMount() {
    requestAnimationFrame(() => {
      this.props.ctx.refreshColors();
    });
  }

  nextCursor = () => {
    //console.log(this.state.archivedProjects.cursor + Project.Preview.PAGE_SIZE);
    return this.state.archivedProjects.cursor + Project.Preview.PAGE_SIZE;
  };

  hasNextPage = () => {
    const nextCursor = this.nextCursor() - 5;
    return nextCursor <= this.state.archivedProjects.total;
  };

  loadNextPage = async () => {
    this.setState({
      request: "loading"
    });
    const newCursor = this.nextCursor();
    
    try {
      const newlyLoaded = await Project.Preview.list(
        this.props.ctx.apolloClient
      )({
        skip: newCursor - 7,
        first: Project.Preview.PAGE_SIZE,
        filter: {
          // onGoing projects are included in the first page
          isOngoing: {
            eq: false
          },
          ...(this.props.filter || {})
        }
      });
      this.setState(
        {
          request: null,
          archivedProjects: {
            ...this.state.archivedProjects,
            cursor: newCursor,
            projects: this.state.archivedProjects.projects.concat(newlyLoaded)
          }
        },
        this.props.ctx.refreshColors
      );
    } catch (e) {
      this.setState({
        request: "error"
      });
    }
  };

  render() {
    if (!this.state) {
      return null;
    }
    const { ctx } = this.props;
    return (
      <div>
        <ProjectArchive className="archive">
          {this.state.archivedProjects.projects.map(project => (
            <ViewArchivedProject key={project.id} project={project} ctx={ctx} />
          ))}
        </ProjectArchive>
        <ProjectArchiveLoadMore className="archive">
        {this.hasNextPage() && (
            <ColorSource
              nodeId="load-more-projects"
              foreground="black"
              background={"rgba(200, 200, 200)"}
              ctx={ctx}
              style={{ background: "rgba(200, 200, 200)", color: "black" }}
              className="load-more-container monospace pointer"
              onClick={
                (this.state.request === null && this.loadNextPage) || undefined
              }
            >
              {this.state.request === "error" && (
                <div className="load-more">An error occured..</div>
              )}
              {this.state.request === "loading" && (
                <div className="load-more">
                  <ViewSpinner color="black" />
                </div>
              )}
              {this.state.request === null && (
                <div className="load-more">Load more</div>
              )}
            </ColorSource>
          )}
          </ProjectArchiveLoadMore>
         </div>
    );
  }
}
