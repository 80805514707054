import { gql } from "apollo-boost";

export interface Color {
  red: number;
  green: number;
  blue: number;
  alpha: number;
  hex: string;
}

export namespace Color {
  export const gqlFragment = gql`
    fragment ColorFragment on ColorField {
      red
      green
      blue
      alpha
      hex
    }
  `;
}
