import { PartnerList } from "./Partner";
import { gql } from "apollo-boost";

export interface AbstractMediaItem {
  id: number;
  __typename: "ImageRecord" | "VideoRecord" | "IframeRecord";
  caption?: string;
  credits: PartnerList[];
}

export interface Iframe extends AbstractMediaItem {
  __typename: "IframeRecord";
  url: string;
}

export interface Video extends AbstractMediaItem {
  __typename: "VideoRecord";
  file: Video.File;
}

export interface Image extends AbstractMediaItem {
  __typename: "ImageRecord";
  file: Image.File;
  proportions: "16:9" | "DEFAULT";
}

export type MediaItem = Iframe | Video | Image;

export interface Gallery {
  items: MediaItem[];
  displayAs: "GRID" | "CAROUSEL" | "STACK GRID";
  caption?: string;
}

const mediaItemCommonFieldsSelection = `
      id
      caption
      credits {
        role
        names {
          name
          url
        }
      }
`.trim();

export namespace Iframe {
  export const gqlFragment = gql`
    fragment IframeFragment on IframeRecord {
      ${mediaItemCommonFieldsSelection}
      url
    }
  `;
}

export namespace Video {
  export interface File {
    url: string;
    thumbnailUrl: string;
    width: number;
    height: number;
  }
  export const gqlFragment = gql`
    fragment VideoFragment on VideoRecord {
      ${mediaItemCommonFieldsSelection}
      file {
        url
        width
        height
        thumbnailUrl
      }
    }
  `;
}

export namespace Image {
  export interface File {
    url: string;
    width: number;
    height: number;
  }

  export const gqlFragment = gql`
    fragment ImageFragment on ImageRecord {
      ${mediaItemCommonFieldsSelection}
      proportions
      file {
        id
        url
        width
        height
      }
    }
  `;
}
