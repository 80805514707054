import Waypoint from "react-waypoint";
import * as React from "react";

export class LazyRender extends React.Component<
  { horizontal?: boolean } & React.HTMLAttributes<any>,
  { doRender: boolean }
> {
  state = {
    doRender: false
  };

  doRender = () => {
    if (!this.state.doRender) {
      this.setState({
        doRender: true
      });
    }
  };

  render() {
    const renderAtOffset =
      typeof window !== "undefined"
        ? (this.props.horizontal && -window.innerWidth) || -window.innerHeight
        : 0;

    return (
      <Waypoint
        bottomOffset={renderAtOffset}
        onEnter={this.doRender}
        horizontal={this.props.horizontal}
      >
        <div {...this.props}>{this.state.doRender && this.props.children}</div>
      </Waypoint>
    );
  }
}
