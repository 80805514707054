import { gql } from "apollo-boost";
import { MediaItem, Image, Video, Iframe } from "../Media";

export namespace Article {
  interface AbstractArticleItem {
    id: string;
    __typename: "TextRecord" | "ArticleMediaItemRecord" | "GalleryRecord";
  }

  export interface MediaItemWrapper extends AbstractArticleItem {
    __typename: "ArticleMediaItemRecord";
    item: MediaItem;
  }

  export interface Text extends AbstractArticleItem {
    __typename: "TextRecord";
    content: string;
  }

  export interface Gallery extends AbstractArticleItem {
    __typename: "GalleryRecord";
    items: MediaItem[];
    displayAs: "GRID" | "CAROUSEL";
  }

  export type Item = MediaItemWrapper | Text | Gallery;
  export namespace TextRecord {
    export const gqlFragment = gql`
      fragment TextRecordFragment on TextRecord {
        id
        content
      }
    `;
  }
  export namespace MediaItem {
    export const gqlFragment = gql`
      fragment MediaItemFragment on ArticleMediaItemRecord {
        id
        item {
          ...ImageFragment
          ...VideoFragment
          ...IframeFragment
        }
      }
      ${Image.gqlFragment}
      ${Video.gqlFragment}
      ${Iframe.gqlFragment}
    `;
  }

  export namespace Gallery {
    export const gqlFragment = gql`
      fragment GalleryFragment on GalleryRecord {
        id
        displayAs
        caption
        items {
          ...ImageFragment
          ...VideoFragment
          ...IframeFragment
        }
      }
      ${Image.gqlFragment}
      ${Video.gqlFragment}
      ${Iframe.gqlFragment}
    `;
  }
}

export type Article = Article.Item[];
