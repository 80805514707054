import * as React from "react";
import Vimeo from "@u-wave/react-vimeo";
import styled from "styled-components";
import { setTimeout, setInterval } from "timers";
import { Video, MediaItem, Gallery, Image } from "src/Data/Media";
import { chunk } from "lodash";
import { ViewSpinner, ViewLoading } from "./Spinner";
import Imgix, { Background } from "react-imgix";
import { Link } from "@reach/router";
import DefaultStackGrid from "react-stack-grid";
import {
  MdNavigateBefore as NavigateLeft,
  MdNavigateNext as NavigateRight,
  MdClose as Close
} from "react-icons/md";
import Waypoint from "react-waypoint";
import { auto } from "async";
import * as imagesLoaded from "imagesloaded";
import { LazyRender } from "src/LazyRender";
import { cssFadeIn, cssFadeOut } from "src/Animations";
import { AspectRatioBox } from "./AspectRatioBox";
import Carousel_ from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { BrowserEvent } from "src/BrowserEvent";

const StackGrid = styled(DefaultStackGrid)`
  .loaded {
    width: 100% !important;
  }

  .imageWrapper:not(.loaded) {
    width: 100% !important;
  }

  .centered {
    display: unset !important;
  }
  img {
    max-height: unset !important;
    width: 100% !important;
  }
`;

type ViewVideoProps = {
  video: Video.File;
  className: string;
  loop?: boolean;
  autoplay?: boolean;
  disableControls?: boolean;
  waypoint?: WaypointConfig;
};

interface ViewVideoState {
  loaded: boolean;
  doRender: boolean;
  play: boolean;
}

const VideoWrapper = styled(AspectRatioBox)`
  &.loaded {
    background: none !important;
  }
  .vimeo-player {
    width: 100%;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .waypoint-child {
    width: 100%;
    height: 100%;
  }
`;

export class ViewVideo extends React.Component<ViewVideoProps, ViewVideoState> {
  state = {
    loaded: false,
    doRender: true,
    play: false
  };

  setLoaded = () => {
    this.setState({
      loaded: true
    });
  };

  play = () => {
    const { autoplay = true } = this.props;
    if (autoplay) {
      this.setState({
        play: true
      });
    }
  };

  pause = () => {
    this.setState({
      play: false
    });
  };

  render() {
    const { video, disableControls = false, loop = true } = this.props;
    return (
      <VideoWrapper
        ratio={video.height / video.width}
        className={`videoWrapper ${this.props.className || ""} ${(this.state
          .loaded &&
          "loaded") ||
          ""}`}
      >
        {!this.state.loaded && <ViewLoading />}
        <LazyRender
          horizontal={this.props.waypoint && this.props.waypoint.horizontal}
          style={{ width: "100%", height: "100%" }}
        >
          <Waypoint
            onEnter={this.play}
            onLeave={this.pause}
            horizontal={this.props.waypoint && this.props.waypoint.horizontal}
          >
            <div className="waypoint-child">
              <Vimeo
                className="vimeo-player"
                video={video.url}
                onLoaded={this.setLoaded}
                background={disableControls}
                muted={!disableControls}
                paused={!this.state.play}
                loop={loop}
              />
            </div>
          </Waypoint>
        </LazyRender>
      </VideoWrapper>
    );
  }
}

const MediaItemContainer = styled.div`
  line-height: 1;
  .caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-style: italic;
    font-size: 0.8rem;
    line-height: 1.2;
  }
`;

type ImageRenderMode =
  | { type: "default" }
  | { type: "aspectRatio"; ratio: [number, number] };

interface ImageRenderOpts {
  sizes?: string;
  renderMode?: ImageRenderMode;
  background?: boolean;
  lazy?: boolean;
}

type ViewImageProps = {
  file: Image.File;
  waypoint?: WaypointConfig;
  to?: string;
} & ImageRenderOpts;

const IMG_MAX_HEIGHT = 900;

const ImageWrapper = styled.div`
  position: relative;

  .background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
  }

  .placeholder.background.loading {
    position: fixed;
    visibility: hidden;
  }

  &.loaded {
    .placeholder {
      position: absolute;
      top: 0;
      left: 0;
    }
    .placeholder {
      ${cssFadeOut(500)};
    }
  }

  &:not(.loaded) {
    .original {
      opacity: 0;
      position: fixed;
      z-index: -1;
    }
  }

  img:not(.forceWidth) {
    width: auto;
    max-height: ${IMG_MAX_HEIGHT}px;
    max-width: 100%;
  }

  img.forceWidth {
    width: 100%;
  }
`;

interface ImageState {
  fullLoaded: boolean;
  placeholderLoaded: boolean;
  renderPlaceholder: boolean;
}

interface WaypointConfig {
  horizontal?: boolean;
}

export class ViewImage extends React.Component<ViewImageProps, ImageState> {
  state = {
    fullLoaded: false,
    placeholderLoaded: false,
    renderPlaceholder: true
  };

  innerWrap: React.ComponentType<any> = props => (
    <React.Fragment>{props.children}</React.Fragment>
  );

  constructor(props: ViewImageProps) {
    super(props);
    if (props.lazy) {
      this.innerWrap = (props: any) => (
        <LazyRender
          horizontal={this.props.waypoint && this.props.waypoint.horizontal}
        >
          {props.children}
        </LazyRender>
      );
    }
  }

  mounted = false;
  componentDidMount() {
    this.mounted = true;
  }

  setStateSafe(args: any) {
    if (this.mounted) {
      this.setState(args);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fullRef = (el: HTMLImageElement | null) => {
    const self = this;
    if (el) {
      const img = imagesLoaded(el);
      img.on("done", () => {
        setTimeout(() => {
          self.setStateSafe({
            fullLoaded: true,
            renderPlaceholder: false
          });
        }, 500);
      });
    }
  };

  placeholderRef = (el: HTMLElement | null) => {
    if (el) {
      const self = this;
      const img = imagesLoaded(el, { background: true });
      img.on("done", () => {
        self.setStateSafe({
          placeholderLoaded: true
        });
      });
    }
  };

  aspectRatioParams = () => {
    const { renderMode } = this.props;
    if (renderMode) {
      switch (renderMode.type) {
        case "default":
          return null;
        case "aspectRatio": {
          return renderMode.ratio;
        }
      }
    }
  };

  renderPlaceHolder = () => {
    const { file, renderMode } = this.props;
    const url = `${file.url}?w=20`;
    const scalePlaceholder = IMG_MAX_HEIGHT / file.height;

    const aspectRatio = this.aspectRatioParams();

    const ratio = aspectRatio
      ? aspectRatio[1] / aspectRatio[0]
      : file.height / file.width;

    // to get with of view// height is 450

    return (
      <AspectRatioBox ratio={9 / 16}>
        <ViewLoading background={"black"} />
      </AspectRatioBox>
    );

    /* using the following as return value would render a low-res blurred placeholder */
    /* if (aspectRatio) {
     *   const ratio = aspectRatio[1] / aspectRatio[0];

     *   const paddingTop = `${ratio * 100}%`;
     *   return (
     *     <React.Fragment>
     *       {!this.state.placeholderLoaded && (
     *         <AspectRatioBox fill={"rgba(0, 0, 0, 1)"} ratio={ratio}>
     *           <ViewLoading />
     *         </AspectRatioBox>
     *       )}
     *       <div
     *         ref={this.placeholderRef}
     *         className={`background placeholder ${
     *           this.state.placeholderLoaded ? "" : "loading"
     *           }`}
     *         style={{ paddingTop, backgroundImage: `url(${url})` }}
     *       />
     *     </React.Fragment>
     *   );
     * }

     * return (
     *   <img
     *     src={url}
     *     className={`placeholder ${this.state.fullLoaded ? "absolute" : ""}`}
     *     style={{
     *       width: `${file.width * scalePlaceholder}px`
     *     }}
     *   />
     * ); */
  };

  render() {
    const { file, sizes = "100vw", to } = this.props;
    const aspectRatio = this.aspectRatioParams();
    const imgixParams = {
      fit: "crop",
      ar: (aspectRatio && `${aspectRatio[0]}:${aspectRatio[1]}`) || undefined
    };

    // landscape or portrait
    var oriantationClass = "portrait"
    if(file.width > file.height) {
      oriantationClass = "landscape"
    }
    //console.log(oriantationClass);

    const InnerWrap = this.innerWrap;
    return (
      <ImageWrapper
        className={`${this.state.fullLoaded ? "loaded" : ""} imageWrapper`}
      >
        {this.state.renderPlaceholder && this.renderPlaceHolder()}
        <InnerWrap>

         {to != undefined &&
          <Link to={to}>
             <Imgix
              sizes={sizes}
              imgixParams={imgixParams}
              src={file.url}
              className={`original  ${oriantationClass} ${aspectRatio ? "forceWidth" : ""}`}
              htmlAttributes={{
                ref: this.fullRef
              }}
            />
          </Link>
         }

         {to == undefined &&
             <Imgix
              sizes={sizes}
              imgixParams={imgixParams}
              src={file.url}
              className={`original  ${oriantationClass} ${aspectRatio ? "forceWidth" : ""}`}
              htmlAttributes={{
                ref: this.fullRef
              }}
            />
         }

        </InnerWrap>
      </ImageWrapper>
    );
  }
}

function renderMediaContent({
  item,
  imageRenderOpts,
  videoProps,
  waypoint
}: {
  item: MediaItem;
  imageRenderOpts?: ImageRenderOpts;
  videoProps?: ViewVideoProps;
  waypoint?: WaypointConfig;
}) {
  switch (item.__typename) {
    case "ImageRecord": {
      return <ViewImage file={item.file} {...imageRenderOpts} />;
    }

    case "IframeRecord":
      return (
        <AspectRatioBox ratio={9 / 16}>
          <iframe
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
            src={item.url}
          />
        </AspectRatioBox>
      );

    case "VideoRecord": {
      const videoProps_ = {
        className: "video",
        video: item.file,
        ...videoProps
      };
      return <ViewVideo {...videoProps_} waypoint={waypoint} />;
    }
  }
}

const BackgroundImage = styled.div<{ src: string; aspectRatio: number }>`
  overflow: hidden;
  height: 0;
  padding-top: ${props => props.aspectRatio * 100}%;
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
`;

const LightBox = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  ${cssFadeIn(250)} .x {
    position: absolute;
    right: 5%;
    top: 2.5%;
    background: url("/close-white.png");
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .x:hover {
    opacity: 0.5;
  }

  .slot {
    padding: 5%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .slider {
      height: initial !important;
    }
  }
`;

export class ViewLightBox extends React.Component<{ onClose: () => void }> {
  componentDidMount() {
    if (typeof document !== "undefined") {
      document.body.classList.add("hide-overflow");
    }
  }

  componentWillUnmount() {
    if (typeof document !== "undefined") {
      document.body.classList.remove("hide-overflow");
    }
  }
  render() {
    return (
      <LightBox className="lightbox" onClick={this.props.onClose}>
        <div className="x" onClick={this.props.onClose} />
        <div className="slot">{this.props.children}</div>
      </LightBox>
    );
  }
}

const Centered = styled.div`
  display: grid;
  justify-content: center;
`;

export class ViewMediaItem extends React.Component<
  {
    mediaItem: MediaItem;
    imageRenderOpts?: ImageRenderOpts;
    videoProps?: ViewVideoProps;
    className?: string;
    captionClassName?: string;
    lightBoxEnabled?: boolean; // if true, and the item is an image, the image is opened in a lightbox on click
    hideCaption?: boolean;
    onClick?: (e: React.MouseEvent) => void;
    waypoint?: WaypointConfig;
  },
  { showLightBox: boolean }
> {
  render() {
    const {
      mediaItem: item,
      imageRenderOpts,
      videoProps,
      waypoint
    } = this.props;

    const viewMediaContent = renderMediaContent({
      item,
      imageRenderOpts,
      videoProps,
      waypoint
    });

    const viewCredits = item.credits.map(partner => (
      <div key={partner.role}>
        {partner.role}:{" "}
        {partner.names.map(({ name, url }, i) => {
          const el = url ? (
            <a key={url} href={url}>
              {name}
            </a>
          ) : (
            <span key={name}>{name}</span>
          );
          return i < partner.names.length - 1 ? (
            <React.Fragment key={name}>{el}, </React.Fragment>
          ) : (
            el
          );
        })}
      </div>
    ));

    const WrapContent =
      item.__typename !== "ImageRecord" ||
      (imageRenderOpts &&
        imageRenderOpts.renderMode &&
        imageRenderOpts.renderMode.type === "aspectRatio")
        ? React.Fragment
        : ({ children }: any) => (
            <Centered className="centered">{children}</Centered>
          );

    return (
      <MediaItemContainer
        onClick={this.props.onClick}
        className={`${this.props.className || ""} mediaItemContainer`}
      >
        <WrapContent>
          {viewMediaContent}
          {!this.props.hideCaption && (
            <div
              className={`caption monospace ${this.props.captionClassName ||
                ""}`}
            >
              <div>{item.caption}</div>
              <div>{viewCredits}</div>
            </div>
          )}
        </WrapContent>
      </MediaItemContainer>
    );
  }
}

interface GalleryProps {
  gallery: Gallery;
  className?: string;
  stackGridColumnWidth?: string | number;
}

const GridGallery = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
`;

interface GalleryState {
  lightBox: number | null;
}


const cacheChildren = (Comp: React.ComponentType) =>
  // workaround for when React's reconciliation breaks down
  // eg. StackGrid
  // children of StackGrid are recreated with every state update
  class extends React.Component<any, any> {
    children: React.ReactNode = [];
    constructor(props: any) {
      super(props);
      this.children = this.props.children;
    }

    render() {
      const { children, ...rest } = this.props;
      return <Comp {...rest}>{this.children}</Comp>;
    }
  };



const ViewStackGrid = cacheChildren(StackGrid);
const Carousel = cacheChildren(Carousel_);

export class ViewGallery extends React.Component<GalleryProps, GalleryState> {
  state: GalleryState = {
    lightBox: null
  };

  openLightBox = [] as (() => void)[];

  constructor(props: GalleryProps) {
    super(props);
    // premake openLightBox functions so that they don't have to be created inside render,
    // and break React's reconciliation process
    this.openLightBox = props.gallery.items.map((_, i) => () =>
      this.setState({
        lightBox: i
      })
    );
  }

  closeLightBox = () => {
    this.setState({
      lightBox: null
    });
  };

  renderGallery() {
    const { displayAs } = this.props.gallery;
    switch (displayAs) {
      case "GRID":
        return (
          <GridGallery className={`gallery grid`}>
            {this.props.gallery.items.map((item, i) => {
              return (
                <ViewMediaItem
                  key={item.id}
                  hideCaption
                  className="pointer"
                  onClick={this.openLightBox[i]}
                  imageRenderOpts={{
                    renderMode: { type: "aspectRatio", ratio: [16, 9] }
                  }}
                  mediaItem={item}
                />
              );
            })}
          </GridGallery>
        );
      case "STACK GRID": {
        return (
          <ViewStackGrid
            className={`gallery stackGrid`}
            columnWidth={this.props.stackGridColumnWidth || "50%"}
          >
            {this.props.gallery.items.map((item, i) => {
              return (
                <ViewMediaItem
                  key={item.id}
                  className="pointer"
                  onClick={this.openLightBox[i]}
                  mediaItem={item}
                  imageRenderOpts={{
                    sizes: "(min-width:1300px) 50vw, 30vw"
                  }}
                />
              );
            })}
          </ViewStackGrid>
        );
      }
      default: {
        return (
          <ViewCarousel
            {...this.props}
            imageRenderOpts={{
              renderMode: { type: "aspectRatio", ratio: [16, 9] },
              sizes: "(min-width: 1300px) 80vw, 100vw",
              lazy: false
            }}
          />
        );
      }
    }
  }

  render() {
    const gallery = this.renderGallery();
    const { className, ...rest } = this.props;
    return (
      <React.Fragment>
        {this.state.lightBox !== null && (
          <ViewLightBox onClose={this.closeLightBox}>
            <ViewCarousel
              fill={false}
              initialIndex={this.state.lightBox}
              gallery={this.props.gallery}
              imageRenderOpts={{
                sizes: "(min-width: 1300px) 80vw, 100vw",
                lazy: false
              }}
            />
          </ViewLightBox>
        )}
        {gallery}
      </React.Fragment>
    );
  }
}

const Circle: React.FunctionComponent<
  {
    radius: number;
    stroke?: [number, string];
    fill?: string;
  } & React.HTMLAttributes<any>
> = props => {
  const { radius, stroke, fill } = props;

  const [strokeWidth, strokeColor] = stroke || [1, "transparent"]; // cannot be null, otherwise circle is cropped;
  const size = (strokeWidth ? radius + strokeWidth : radius) * 2;

  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      onClick={e => {
        e.stopPropagation();
        props.onClick && props.onClick(e);
      }}
    >
      <circle
        cx={radius + (strokeWidth || 0)}
        cy={radius + (strokeWidth || 0)}
        r={radius}
        stroke={strokeColor || undefined}
        strokeWidth={strokeWidth}
        fill={fill || "none"}
      />
    </svg>
  );
};

const CircleNav = styled.div`
  display: flex;
  svg:not(:last-child) {
    padding-right: 5px;
  }
`;

const ViewCircleNav: React.FunctionComponent<{
  total: number;
  current: number;
  className?: string;
  onNav?: (index: number) => void;
}> = props => {
  const elements = Array.from(Array(props.total).keys()).map(
    i =>
      i === props.current ? (
        <Circle key={i} radius={5} fill="currentColor" />
      ) : (
        <Circle
          key={i}
          onClick={() => props.onNav(i)}
          radius={5}
          stroke={[1, "currentColor"]}
        />
      )
  );

  return <CircleNav className={props.className || ""}>{elements}</CircleNav>;
};

const CarouselWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.05fr 1fr 0.05fr;
  grid-template-areas:
    "control-left slider control-right"
    ". dots .";

  .BrainhubCarousel {
    grid-area: slider;
    touch-action: pan-x;
  }

  @media (max-width: 1300px) {
    grid-template-areas:
      "slider slider slider"
      ". dots .";
    .control-column {
      display: none;
    }
  }

  .control-wrapper {
    width: 50px;
    height: 50px;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .control-wrapper:hover {
    opacity: 0.2;
  }

  @media (min-width: 1300px) {
    .control-column {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .control-column.left {
      grid-area: control-left;
    }

    .control-column.right {
      grid-area: control-right;
    }
  }

  .dots {
    user-select: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    grid-area: dots;
    display: flex;
    justify-content: center;
    svg {
      cursor: pointer;
    }
  }

  .imageWrapper.loaded {
    width: unset !important;
  }

  &.fill {
    position: relative;
    display: block;
    .control-column {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 9;
    }
    svg {
      fill: white;
    }
    .control-wrapper {
      background: rgba(0, 0, 0, 0.2);
    }
    .control-column.left {
      left: 0;
    }
    .control-column.right {
      right: 0;
    }
    .BrainhubCarouselItem > .mediaItemContainer {
      width: 100%;
    }
  }
`;

interface CarouselState {
  index: number;
}

type CarouselProps = {
  initialIndex?: number;
  imageRenderOpts?: ImageRenderOpts;
  fill?: boolean;
} & GalleryProps;

export class ViewCarousel extends React.Component<
  CarouselProps,
  CarouselState
> {
  state = {
    index: 0
  };

  sliderNode: HTMLElement | null = null;

  constructor(props: CarouselProps) {
    super(props);
    this.state = {
      index: props.initialIndex || 0
    };
  }

  next = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      index: this.state.index + 1
    });
  };

  prev = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      index: this.state.index - 1
    });
  };

  onChange = (index: number) => {
    this.setState({
      index
    });
  };

  // modulo which works with negative numbers
  mod = (x: number, n: number) => ((x % n) + n) % n;

  render() {
    const { fill = true } = this.props;
    const { items: galleryItems } = this.props.gallery;
    if (galleryItems.length === 0) {
      return null;
    }

    return (
      <CarouselWrapper
        className={`gallery carousel-wrapper ${fill ? "fill" : ""}`}
      >
        <div className="control-column left">
          <div className="control-wrapper" onClick={this.prev}>
            <NavigateLeft className="control" />
          </div>
        </div>
        <Carousel
          animationSpeed={500}
          infinite
          offset={fill ? 0 : 10}
          value={this.state.index}
          onChange={this.onChange}
        >
          {this.props.gallery.items
            .map(item => {
              const galleryCaption = this.props.gallery.caption;
              if (!item.caption && galleryCaption) {
                return {
                  ...item,
                  caption: galleryCaption
                };
              }
              return item;
            })
            .map(item => (
              <ViewMediaItem
                waypoint={{ horizontal: true }}
                key={item.id}
                mediaItem={item}
                onClick={BrowserEvent.stopPropagation}
                imageRenderOpts={{
                  ...this.props.imageRenderOpts,
                  lazy: false
                }}
              />
            ))}
        </Carousel>
        <div className="control-column right">
          <div className="control-wrapper" onClick={this.next}>
            <NavigateRight className="control" />
          </div>
        </div>
        <div className="dots">
          <ViewCircleNav
            total={galleryItems.length}
            current={this.mod(this.state.index, galleryItems.length)}
            onNav={this.onChange}
          />
        </div>
      </CarouselWrapper>
    );
  }
}
