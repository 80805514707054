import { Article } from "./Article";
import { gql, QueryOptions, ApolloClient } from "apollo-boost";
import { Fact } from "./Fact";
import { PartnerList } from "../Partner";
import { Color } from "../Color";
import { Image, Iframe, Video, MediaItem } from "../Media";

export namespace Project {
  export interface Full {
    id: string;
    slug: string;
    featured: boolean,
    title: string;
    kicker?: string;
    introText: string;
    date: string;
    location?: Location;
    cover: MediaItem;
    coverImage?: Image;
    textColor: Color;
    backgroundColor: Color;
    article: Article.Item[];
    partners: PartnerList[];
    otherFacts: Fact[];
    isOngoing: boolean;
  }

  export namespace Full {
    export const gqlFragment = gql`
      fragment FullProjectFragment on ProjectRecord {
        id
        slug
        title
        featured
        kicker
        introText
        date
        isOngoing
        location {
          label
          geolocation {
            latitude
            longitude
          }
        }
        otherFacts {
          label
          value
          url
        }
        cover {
          __typename
          ...ImageFragment
          ...VideoFragment
          ...IframeFragment
        }
        coverImage {
          ...ImageFragment
        }
        backgroundColor {
          ...ColorFragment
        }
        textColor {
          ...ColorFragment
        }
        article {
          __typename
          ...MediaItemFragment
          ...TextRecordFragment
          ...GalleryFragment
        }
        partners {
          role
          names {
            id
            id
            name
            url
          }
        }
      }
      ${Image.gqlFragment}
      ${Iframe.gqlFragment}
      ${Video.gqlFragment}
      ${Article.TextRecord.gqlFragment}
      ${Article.MediaItem.gqlFragment}
      ${Article.Gallery.gqlFragment}
      ${Color.gqlFragment}
    `;

    export function fetch(projectSlug: string): QueryOptions {
      return {
        query: gql`
       query project {
         project(filter: {slug: {eq: "${projectSlug}"}}){
           ...FullProjectFragment
         }
       }
       ${Project.Full.gqlFragment}
     `
      };
    }

    export async function loadAll(apolloClient: ApolloClient<{}>) {
      const query = gql`
        query FullProjects {
          projects: allProjects( first: 100 ) {
            ...FullProjectFragment
          }
        }
        ${Project.Full.gqlFragment}
      `;

      const result = await apolloClient.query<Project.Full[]>({ query });
      return result.data;
    }
  }

  export interface Featured {
    id: string;
    slug: string;
    title: string;
    featured: boolean,
    kicker?: string;
    introText: string;
    date: string;
    cover: MediaItem;
    coverImage?: Image;
    textColor: Color;
    backgroundColor: Color;
    isOngoing: boolean;
  }

  export namespace Featured {
    export const gqlFragment = gql`
      fragment FeaturedProjectFragment on ProjectRecord {
        id
        slug
        title
        featured
        kicker
        introText
        date
        isOngoing
        textColor {
          ...ColorFragment
        }
        backgroundColor {
          ...ColorFragment
        }
        cover {
          __typename
          ...ImageFragment
          ...VideoFragment
          ...IframeFragment
        }
        coverImage {
          ...ImageFragment
        }
      }
      ${Image.gqlFragment}
      ${Video.gqlFragment}
      ${Iframe.gqlFragment}
      ${Color.gqlFragment}
    `;

    export async function load(apolloClient: ApolloClient<{}>) {
      const QUERY = gql`
        query FeaturedProjects {
          featuredProjects: allProjects(
            orderBy: date_DESC,
            first: 100
          ) {
            ...FeaturedProjectFragment
          }
        }
        ${Project.Featured.gqlFragment}
      `;
      const result = await apolloClient.query<{
        featuredProjects: Project.Featured[];
      }>({ query: QUERY });
      return result.data.featuredProjects;
    }
  }
  // //filter: { featured: { eq: true } }
            

  export interface Preview {
    id: string;
    featured: boolean,
    title: string;
    kicker?: string;
    cover: MediaItem;
    coverImage?: Image;
    date: string;
    slug: string;
    backgroundColor: Color;
    textColor: Color;
    isOngoing: boolean;
  }

  export namespace Preview {
    export const PAGE_SIZE = 6;
    export const gqlFragment = gql`
      fragment PreviewProjectFragment on ProjectRecord {
        id
        title
        featured
        kicker
        slug
        date
        isOngoing
        cover {
          __typename
          ...ImageFragment
          ...VideoFragment
          ...IframeFragment
        }
        coverImage {
          ...ImageFragment
        }
        backgroundColor {
          ...ColorFragment
        }
        textColor {
          ...ColorFragment
        }
      }
      ${Color.gqlFragment}
      ${Image.gqlFragment}
      ${Video.gqlFragment}
      ${Iframe.gqlFragment}
      ${Color.gqlFragment}
    `;

    type FilterType<T> = {
      eq?: T;
      neq?: T;
    };

    export type Filter = {
      featured?: FilterType<boolean>;
      isOngoing?: FilterType<boolean>;
      slug?: FilterType<string>;
    };

    type ListOpts = {
      filter?: Filter;
      first?: number;
      skip?: number;
    };

    export interface PagedProjects {
      cursor: number;
      total: number;
      projects: Project.Preview[];
    }

    // this one is meant to be used only during static rendering, from static.config.js
    // loads a page of archived projects as well as ongoing projects
    // the ongoing projects are appended to the first page of archived projects
    export const load = async (apolloClient: ApolloClient<{}>) => {
      
  
  
      const allProjects = await list(apolloClient)({ first: 100 });
      // console.log('allProjects', allProjects.length);
      return {
        all: () => allProjects,
        paged: function(
          filterFn?: (p: Project.Preview) => boolean
        ): PagedProjects {
          const [ongoingProjects, otherProjects] = allProjects
            .filter(filterFn || (() => true))
            .reduce(
              (acc, p) => {
                // console.log("preview ongoing ######################################### ", p.title);
                const [accOngoing, accOther] = acc;
                return p.isOngoing ? [accOngoing.concat(p), accOther]  : [accOngoing, accOther.concat(p)];
              },
              [[], []]
            );
            
          // console.log("######################################################################### START")  
          
          // console.log(allProjects);
          const cursor = PAGE_SIZE;  //  + ongoingProjects.length
        
          // console.log("cursor", cursor);
          // console.log("all", allProjects.length)

          // console.log("AllProjects", allProjects.length);
          // allProjects.forEach((item, index)=> {
          //   // console.log(index, item.title)
          // });

          // // console.log("total ongoingProjects", ongoingProjects.length);
          // ongoingProjects.forEach((item, index)=> {
          //   // console.log(index, item.title)
          // });

          // // console.log("total otherProjects", otherProjects.length);
          // otherProjects.forEach((item, index)=> {
          //   // console.log(index, item.title)
          // });

          // console.log("concat");
            
          const concatt = (ongoingProjects.concat(
            otherProjects
          )).filter((_, i) => i < cursor)

          // console.log("after cursor", cursor);

          // concatt.forEach((item, index)=> {
          //   // console.log(index, item.title)
          // });
          
          // console.log("######################################################################### END")
  
          return {
            cursor,
            total: otherProjects.length,
            projects: concatt
          };
        }
      };
    };

    export const list = (apolloClient: ApolloClient<{}>) => async (
      opts?: ListOpts
    ) => {
      const query = gql`
        query ProjectPreviews(
          $filter: ProjectModelFilter
          $skip: IntType
          $first: IntType
        ) {
          projects: allProjects(
            filter: $filter
            orderBy: date_DESC
            skip: $skip
            first: $first
          ) {
            ...PreviewProjectFragment
          }
        }
        ${Project.Preview.gqlFragment}
      `;
      const result = await apolloClient.query<{ projects: Project.Preview[] }>({
        query,
        variables: opts
      });
      return result.data.projects;
    };
  }
}
